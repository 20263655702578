import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Image from '~components/Image'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import SanityLink from '~components/SanityLink'
import { mobile, HeaderImageDesk, HeaderImageMobile } from '~styles/global'
import RenderModules from '~components/RenderModules'
import { useLocation } from '@reach/router'

const Loan = ({ data }) => {
	const { page } = data
	const loc = useLocation()
	// force hash scroll
	useEffect(() => {
		if(loc.hash){
			const el = document.querySelector(loc.hash)
			let objectPos = window.pageYOffset + el.getBoundingClientRect().top
			window.scrollTo({
				top: objectPos - 100,
				left: 0,
				behavior: 'smooth'
			})
		}
	}, [loc])
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Header>
				<HeaderImageDesk image={page?.headerImage} cover hideMobile={page.headerImageMobile}/>
				<HeaderImageMobile image={page.headerImageMobile} cover/>
				<HeaderContent>
					<HeaderContentInner>
						<HeaderContentOverlay/>
						<Heading>{page.heading}</Heading>
						<BenefitsDesk>
							{page.benefits?.map(benefit => (
								<Benefit key={benefit.heading}>
									<BenefitHeading>{benefit.heading}</BenefitHeading>
									<RichText content={benefit.text}/>
								</Benefit>
							))}
						</BenefitsDesk>
					</HeaderContentInner>
				</HeaderContent>
			</Header>
			<BenefitsMobSection>
				<BenefitsMob>
					{page.benefits?.map(benefit => (
						<Benefit key={benefit.heading}>
							<BenefitHeading>{benefit.heading}</BenefitHeading>
							<RichText content={benefit.text}/>
						</Benefit>
					))}
				</BenefitsMob>
			</BenefitsMobSection>
			<RenderModules modules={page.modules}/>
			<div id='test'></div>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Header = styled.div`
	position: relative;
	${mobile}{
		margin-bottom: 27px;
	}
`
const HeaderContent = styled(Section)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	>div{
		height: 100%;
		${mobile}{
			padding: 0;
		}
	}
`

const HeaderContentInner = styled.div`
	align-self: end;
	grid-column: 1/13;
	max-width: 850px;
	width: 100%;
	margin: 0 auto 62px;
	position: relative;
	padding: 34px;
	box-sizing: border-box;
	z-index: 1;
	color: var(--white);
	${mobile}{
		margin: 0 auto;
		padding: 14px 20px 40px;
	}
`
const HeaderContentOverlay = styled.div`
	position: absolute;
	background: #000000;
	mix-blend-mode: multiply;
	opacity: 0.5;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -1;
	border-radius: 15px;
	${mobile}{
		border-radius: 0;
	}
`
const Heading = styled.h1`
	margin-bottom: 90px;
	${mobile}{
		margin-bottom: 30px;
	}
`
const BenefitsDesk = styled.div`
	display: flex;
	${mobile}{
		display: none;
	}
`
const Benefit = styled.div`
	width: 33.33%;
	border-right: 1px solid var(--white);
	padding: 0 20px 0px;
	:first-of-type{
		padding-left: 0;
	}
	:last-of-type{
		border-right: none;
	}
	${mobile}{
		width: 100%;
		border-right: 0;
		border-bottom: 1px solid var(--extraDarkGrey);
		margin-bottom: 27px;
		padding: 0;
		padding-bottom: 23px;
		:last-of-type{
			border-bottom: none;
		}
	}
`
const BenefitHeading = styled.h2`
	margin-bottom: 54px;
	${mobile}{
		margin-bottom: 24px;
	}
`
const BenefitsMobSection = styled(Section)`
	display: none;
	${mobile}{
		display: block;
		grid-column: span 12;
		border-bottom: 1px solid var(--extraDarkGrey);
	}
`
const BenefitsMob = styled.div`
	grid-column: span 12;
`

Loan.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query LoanQuery($slug: String) {
    page: sanityLoan(slug: {current: {eq: $slug}}) {
      title
			heading
			benefits {
				heading
				text: _rawText
			}
			headerImage {
				...imageWithAlt
			}
			headerImageMobile {
				...imageWithAlt
			}
			modules {
				... on SanityAccordion {
					_key
					_type
					title
					background
					sections {
						_key
						title
						text: _rawText
					}
				}
				... on SanityArticleCollection {
					_key
					_type
					backgroundGradient
					articles {
						...articleThumb
					}
				}
				... on SanityCalculator {
					_key
					_type
					title
					stampDutyCalculator{
						_key
						embedCode
						# title
					}
					loanRepaymentCalculator{
						_key
						embedCode
						# title
					}
				}
				... on SanityContactForm {
					_key
					_type
					backgroundGradient
					title
					text: _rawText
				}
				... on SanityImageWithCaption {
					_key
					_type
					...imageWithCaption
				}
				... on SanityTextOnImage {
					_key
					_type
					image {
						...imageWithAlt
					}
					textColumns {
						heading
						text: _rawText
					}
				}
			}
			seo{
				...seo
			}
    }
  }
`

export default Loan